export class APIBase {
  constructor(app, net) {
    this.app = app;
    this.net = net;
  }

  send(request) {
    if (request.use_token && !this.app.$appm.current_user_token_is_valid()) {
      this.app.$appm.on_api_call_requires_token();
      return;
    }

    if (request.refresh_interval == null) {
      request.refresh_interval = global.default_refresh_interval;
    }
    request.caller_name = this.constructor.name;
    let push_result = this.net.queue.push_to_send_queue(request);
    if (push_result === "not_necessary") {
      if (request.on_not_necessary != null) {
        request.on_not_necessary(this.app);
      }
      return null;
    }
    return push_result;
  }
}
