import NetworkQueue from "./api/networkqueue.js";
import ArriveQueue from "./api/arrivequeue.js";

import { AccessCollectionAPI } from "./api/access/accesscollection.js";
import { AccessCollectionGrantAPI } from "./api/access/accesscollectiongrant.js";
// import { ChannelAPI } from "./api/channel.js";
import { CPDataAPI } from "./api/cpdata.js";
import { CustomerAPI } from "./api/customer.js";
import { FirmwareAPI } from "./api/firmware.js";
import { CustomerGrantAPI } from "./api/access/customergrant.js";
import { CustomerProjectAPI } from "./api/customerproject.js";
import { FormulaAPI } from "./api/formula.js";
import { NodeAPI } from "./api/node.js";
import { ProjectAPI } from "./api/project.js";
import { ProjectGrantAPI } from "./api/access/projectgrant.js";
import { RecordingInfoAPI } from "./api/recordings/recinfo.js";
import { RecordingInfoDecayAPI } from "./api/recordings/recinfodecay.js";
import { RecordingInfoLPRAPI } from "./api/recordings/recinfolpr.js";
import { RecordingInfoMonitorAPI } from "./api/recordings/recinfomonitor.js";
import { ServerLogAPI } from "./api/serverlog.js";
import { StaticFilesAPI } from "./api/staticfiles.js";
import { UserAPI } from "./api/user.js";
import { ZoneAPI } from "./api/zone.js";
import { CntrlAPI } from "./api/cntrl.js";
import { CntrlDeviceAPI } from "./api/cntrl_device.js";
import { GraphData } from "./api/graphdata.js";
import { ServiceLogAPI } from "./api/servicelog.js";
import { EventLogAPI } from "./api/eventlog.js";
import { GraphPropsAPI } from "./api/graph_props.js";
import { AlarmSubscribersAPI } from "./api/alarm_subscribers.js";

export default {
  init(app) {
    this.app = app;
    this.queue = new NetworkQueue(this, 10);
    this.arrive_queue = new ArriveQueue(this.apply_server_response, app);

    this.access_collection = new AccessCollectionAPI(app, this);
    this.access_collection_grant = new AccessCollectionGrantAPI(app, this);
    // this.channel = new ChannelAPI(app, this);
    this.cp_data = new CPDataAPI(app, this);
    this.customer = new CustomerAPI(app, this);
    this.firmware = new FirmwareAPI(app, this);
    this.customer_grant = new CustomerGrantAPI(app, this);
    this.customer_project = new CustomerProjectAPI(app, this);
    this.formula = new FormulaAPI(app, this);
    this.node = new NodeAPI(app, this);
    this.project = new ProjectAPI(app, this);
    this.project_grant = new ProjectGrantAPI(app, this);
    this.recording_info = new RecordingInfoAPI(app, this);
    this.recording_info_decay = new RecordingInfoDecayAPI(app, this);
    this.recording_info_lpr = new RecordingInfoLPRAPI(app, this);
    this.recording_info_monitor = new RecordingInfoMonitorAPI(app, this);
    this.server_log = new ServerLogAPI(app, this);
    this.staticfiles = new StaticFilesAPI(app, this);
    this.user = new UserAPI(app, this);
    this.zone = new ZoneAPI(app, this);
    this.cntrl = new CntrlAPI(app, this);
    this.cntrl_device = new CntrlDeviceAPI(app, this);
    this.graph_data = new GraphData(app, this);
    this.servicelog = new ServiceLogAPI(app, this);
    this.eventlog = new EventLogAPI(app, this);
    this.graph_props = new GraphPropsAPI(app, this);
    this.alarm_subscribers = new AlarmSubscribersAPI(app, this);
  },

  send_request(request) {
    if (request.server_domain == null) request.server_domain = this.api_server;
    if (request.use_token == null) request.use_token = true;
    if (request.parse_response_as_json == null)
      request.parse_response_as_json = true;

    // encodeURI() will cause problem when request includes components already
    // encoded with encodeURIComponent -> will cause double-encoding
    // and encodeURI() only will not do the work. E.g. converting ':' in a filter in the URL
    // var url = request.server_domain + "/" + encodeURI(request.command);
    var url = request.server_domain + "/" + request.command;
    var xhr = new XMLHttpRequest();

    if (this.has_modern_cors_support(xhr)) {
      var async = true;
      xhr.open(request.method, url, async);
    } else if (this.has_xdomain_support()) {
      // eslint-disable-next-line
      xhr = new XDomainRequest();
      xhr.open(request.method, url);
    } else {
      alert("CORS not supported");
      return;
    }

    xhr.onreadystatechange = this.receive_server_response;
    xhr.ontimeout = this.handle_timeout;
    xhr.myself = this;
    xhr.request_info = request;
    if (request.custom_callback != null) {
      xhr.custom_callback = request.custom_callback;
    }

    if (request.use_token) {
      if (this.app.$appm.current_user_token_is_valid()) {
        let token = localStorage.usertoken;
        xhr.setRequestHeader("Authorization", "Bearer " + token);
      } else {
        this.app.$appm.on_api_call_requires_token();
        return null;
      }
    }

    if (request.responseType != null) {
      xhr.responseType = request.responseType;
      console.log("Set responseType:"+request.responseType);
    }

    var payload = null;
    if (request.post_data) {
      payload = request.post_data;
    } else if (request.post_json) {
      // Don't send empty update requests!
      if (Object.keys(request.post_json).length == 0) return;

      xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
      payload = JSON.stringify(request.post_json);
    }

    if (payload != null) xhr.send(payload);
    else xhr.send();
    return xhr;
  },

  has_modern_cors_support(xhr) {
    return "withCredentials" in xhr;
  },

  has_xdomain_support() {
    return typeof XDomainRequest != "undefined";
  },

  receive_server_response(response) {
    var myself = response.target.myself;
    if (myself.app) myself.app.netstate += 1;

    if (myself.response_is_ready(response)) {
      if (myself.response_status_is_ok(response)) {
        // Because vue adds getters and setters to all
        // properties, it seems to mess up the class
        // methods, so I set the properties directly
        response.target.request_info.finished = true;
        myself.arrive_queue.arrive(response, myself);
        myself.arrive_queue.apply_queue();
      } else {
        myself.handle_response_error(response);
      }
    }
  },

  apply_server_response(response, net) {
    if (response.target.responseType == "blob") {
      var payload = response.target.response;
      response.target.request_info.on_receive(
        payload,
        response.target.request_info.identification,
        net
      );
  
      if (response.target.custom_callback != null) {
        response.target.custom_callback(
          net.app,
          response.target.request_info.identification,
          payload
        );
      }  

    } else {
      var payload = net.parse_response_text(response);
      if (response.target.request_info.on_receive != null) {
        response.target.request_info.on_receive(
          payload,
          response.target.request_info.identification,
          net
        );          
      }
  
      if (response.target.custom_callback != null) {
        response.target.custom_callback(
          net.app,
          response.target.request_info.identification,
          payload
        );
      }  
    }
  },

  response_is_ready(response) {
    var XHR_STATE_READY = 4;
    return response.target.readyState === XHR_STATE_READY;
  },

  response_status_is_ok(response) {
    return response.target.status === 200 || response.target.status === 201;
  },

  handle_400_error(response){
      var error_body = JSON.parse(response.target.responseText);
      if(error_body.title == "Unable to delete a controller with existing zones."){
        return this.app.$appm.unable_to_delete_controller();
      }
      else{
          let error_text = "";
          try {
            error_text = this.parse_response_text(response);
          } catch (error) {
            error_text = `Could not parse error message`;
          }
    
          return this.app.$appm.on_default_server_error(response.target.status, error_text);
      }
  },
  handle_423_error(response){
    var error_body = JSON.parse(response.target.responseText);
    if(error_body.title == "Current controller state Ready can not handle the request."){
      this.app.$appm.unable_to_unassign_node();
    }
    else{
      let error_text = "";
      try{
        error_text = this.parse_response_text(response);
      }
      catch(error){
        error_text = `Could not parse error message`;
      }
      return this.app.$appm.on_default_server_error(response.target.status, error_text);
    }
  },
  handle_response_error(response) {
    // Because vue adds getters and setters to all
    // properties, it seems to mess up the class
    // methods, so I set the properties directly
    response.target.request_info.error_code = response.target.status;

    switch (response.target.status) {
        case 400: {
          this.handle_400_error(response);
        }
        break;
      case 401:
        {
          //Get rid of the 401 error status codes
          if (response.target.request_info.use_token === true) {
            this.app.$appm.on_signature_expired();
          } else if (
            response.target.request_info.command === "users/authenticate"
          ) {
            this.app.$appm.on_login_failed();
          }
        }
        break;

      case 423: {
          this.handle_423_error(response);
        }
        break;
        
      case 503:
        {
          this.app.$appm.on_server_temporarily_unavailable();
        }
        break;

      case 500:
        {
          this.app.$appm.on_internal_server_error(
            response.target.request_info.command
          );
        }
        break;

      case 404:
        {
          if (
            response.target.responseURL.endsWith("controller/serials/claim/")
          ) {
            this.app.$appm.show_note(this.app.$txt.no_such_cntrl);
            break;
          } else if (response.target.request_info.method == "DELETE")
          { // May get 404 when deleting a node
            break;
          }
          else 
          {
            var server_message = this.parse_response_text(response);
            this.app.$appm.on_default_server_error(
              response.target.status,
              server_message
            );
          }
        }
        break;
      case 409: {
        // The HTTP 409 Conflict response status code indicates a request
        // conflict with current state of the target resource.

        if (
          response.target.responseURL.includes("controller") &&
          response.jobId !== undefined
        ) {
          // This means that the server denied a job request because the
          // controller already has a job
          this.app.$appm.on_cntrl_job_conflict(
            response.jobId,
            response.taskName,
            response.projectId,
            response.data.ControllerId,
            response.target.request_info.command
          );
        } else {
          this.app.$appm.on_request_conflict();
        }
      }

      case 0:
        {
          this.handle_timeout();
        }
        break;

      default: {
        // Sometimes we get a html page back (500 error), so we can't
        // just parse it as JSON
        let error_text = "";

        try {
          error_text = this.parse_response_text(response);
        } catch (error) {
          error_text = `Could not parse error message`;
        }

        this.app.$appm.on_default_server_error(response.target.status, error_text);
      }
    }
  },

  handle_timeout() {
    this.app.$appm.on_request_time_out();
  },

  parse_response_text(response) {
    var empty = "";

    if (response.target.responseText == "") return empty;

    if (response.target.request_info.parse_response_as_json) {
      return JSON.parse(response.target.responseText);
    } else {
      return response.target.responseText;
    }
  },

  receive_not_implemented(response, identification, myself, funcname) {
    throw `Receive function "${funcname}" is not implemented: ${{
      response,
      identification,
      myself
    }}`;
  }
};
